import Block from '../landing/elements/Block'
import Col from 'react-bootstrap/Col'
import Card from '../landing/elements/Card'
import Image from '../landing/elements/Image'
import useIsMobile from '../../hooks/useIsMobile'
import Button from 'react-bootstrap/Button'

export default function SectionIntro ({ title, text }) {
  const isMobile = useIsMobile()

  return (
    <Block
      id={'intro'}
      background={'bg-light'}
      fullWidth
      alignVertical
      className={'overflow-hidden'}
      g={'g-1 g-md-4'}
    >
      <Col
        xs={{ span: 12, order: 2 }}
        md={{ span: 6, order: 1 }}
      >
        <Card
          gap={4}
          className={'pb-5 pt-0 pb-mt-5'}
        >
          <h1 className={'fs-3 m-0'}>{title}</h1>
          <p className={'m-0 opacity-75 fs-5'}>{text}</p>
          <Button
            variant={'success'}
            style={{ maxWidth: 200 }}
            onClick={() => {
              const nextContainer = document.getElementById('intro').nextElementSibling
              nextContainer.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            Shop Now
          </Button>
        </Card>
      </Col>
      <Col
        xs={{ span: 12, order: 1 }}
        md={{ span: 6, order: 2 }}
      >
        <div className={'p-md-0 p-3'}>
          <Image
            height={isMobile ? 350 : 450}
            contain
            title={title}
            src={'/img/ivystore/index.png'}
          />
        </div>
      </Col>
    </Block>
  )
}