import Block from '../landing/elements/Block'
import Link from 'next/link'
import Button from 'react-bootstrap/Button'
import Card from '../landing/elements/Card'
import Col from 'react-bootstrap/Col'
import Image from '../landing/elements/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import useIsMobile from '../../hooks/useIsMobile'

export default function SectionCouchRemoval ({ stateData, cityData }) {
  const isMobile = useIsMobile()

  return (
    <Block
      fullWidth
      alignVertical
      background={'bg-light'}
      className={'overflow-hidden'}
    >
      <Col
        xs={{ span: 12, order: 2 }}
        md={{ span: 6, order: 1 }}
      >
        <Card
          className={'pb-5 pt-0 pb-mt-5'}
        >
          <h2 className={'fs-4 m-0'}>
            Free Couch Removal
          </h2>
          <p className={'m-0 opacity-75'}>
            Ready to get rid of your used couch?
            <br />
            Fill out our quick form and see if you are eligible for free couch removal!
            <br />
            No heavy lifting, no hassle.
            It’s the simplest way to clear your space and give your used sofa a second life.
          </p>

          <Link
            href={`/pickup/${stateData ? `/${stateData.slug}` : ''}${cityData ? `/${cityData.slug}` : ''}`}>
            <Button variant={'success'}>
              Submit Pickup Request
              <FontAwesomeIcon
                icon={faArrowRight}
                width={16}
                height={16}
                className={'ms-2'}
              />
            </Button>
          </Link>

          <div className="fst-italic opacity-75">
            Get response within 1 business day
          </div>
        </Card>
      </Col>
      <Col
        xs={{ span: 12, order: 1 }}
        md={{ span: 6, order: 2 }}
      >
        <Image
          height={isMobile ? 350 : 450}
          contain
          title='Free Couch Removal'
          src={'/img/couch-pickup/couch-pickup.png'}
        />
      </Col>
    </Block>
  )
}