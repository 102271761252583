import Block from '../landing/elements/Block'
import Col from 'react-bootstrap/Col'
import Card from '../landing/elements/Card'
import Image from '../landing/elements/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Link from 'next/link'

const advantages = [
  {
    image: '/img/ivystore/f1.png',
    title: 'Quality You Can Trust',
    text: 'Every seller undergoes a strict vetting process, ensuring that every piece meets our high standards.'
  },
  {
    image: '/img/ivystore/f3.png',
    title: 'Fast, Convenient Delivery',
    text: 'No more waiting weeks for a new couch. With our local flipping network, get your new sofa in 1–3 days.'
  },
  {
    image: '/img/ivystore/f2.png',
    title: 'Sourced from Top Brands',
    text: 'Enjoy incredible deals on brand-name returns, liquidations, or pre-loved couches from people just like you—without compromising on style or comfort.'
  },
  {
    image: '/img/ivystore/f4.png',
    title: 'Thorough Inspection',
    text: 'Each item is carefully checked for defects or damage so you can shop with total peace of mind.'
  },
]

export default function SectionAdvantages ({ stateData, cityData }) {
  return (
    <Block
      background={'bg-light px-4 p-md-5'}
      g={'g-3 g-md-5'}
    >
      <Col xs={12}>
        <h2 className={'fs-4 m-0 mb-3'}>
          Key Benefits
        </h2>
      </Col>

      {advantages.map((advantage, index) => (
        <Col
          key={index}
          xs={12} md={6}
        >
          <Card gap={2}>
            <Image
              title={advantage.title}
              src={advantage.image}
              width={100}
              height={100}
            />
            <h3 className={'m-0 fs-5'}>
              {advantage.title}
            </h3>
            <p className={'m-0 opacity-75'}>
              {advantage.text}
            </p>
          </Card>
        </Col>
      ))}

      <Col xs={12}>
        <Link
          href={`/products/${stateData ? `?state=${stateData.slug}` : ''}${cityData ? `&city=${cityData.slug}` : ''}`}
          className={'mt-3'}
        >
          <Button variant={'success'}>
            Shop Now
            <FontAwesomeIcon
              icon={faArrowRight}
              width={16}
              height={16}
              className={'ms-2'}
            />
          </Button>
        </Link>
      </Col>
    </Block>
  )
}