import Block from '../landing/elements/Block'
import Link from 'next/link'
import Button from 'react-bootstrap/Button'
import Card from '../landing/elements/Card'
import Col from 'react-bootstrap/Col'
import Image from '../landing/elements/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import useIsMobile from '../../hooks/useIsMobile'

export default function SectionFindCouch ({ stateData, cityData }) {
  const isMobile = useIsMobile()

  return (
    <Block
      fullWidth
      alignVertical
      background={'bg-light'}
      className={'overflow-hidden'}
    >
      <Col
        xs={{ span: 12, order: 2 }}
        md={{ span: 6, order: 1 }}
      >
        <Card
          className={'pb-5 pt-0 pb-mt-5'}
        >
          <h2 className={'fs-4 m-0'}>
            Shop our Full Collection
          </h2>
          <p className={'m-0 opacity-75'}>
            Brand-name, gently used sofas, sectionals,
            and couches for <span className="fw-semibold">60–70% off retail—all inspected and delivered</span> right
            to your door in just <span className="fw-semibold">1–3 days</span>.
          </p>

          <Link
            href={`/products/${stateData ? `?state=${stateData.slug}` : ''}${cityData ? `&city=${cityData.slug}` : ''}`}>
            <Button variant={'success'}>
              Make a personalized selection
              <FontAwesomeIcon
                icon={faArrowRight}
                width={16}
                height={16}
                className={'ms-2'}
              />
            </Button>
          </Link>
        </Card>
      </Col>
      <Col
        xs={{ span: 12, order: 1 }}
        md={{ span: 6, order: 2 }}
      >
        <Image
          height={isMobile ? 350 : 450}
          contain
          title='Find a used and inexpensive sofa in one click'
          src={'/img/ivystore/search.png'}
        />
      </Col>
    </Block>
  )
}