import Block from '../landing/elements/Block'
import Col from 'react-bootstrap/Col'
import Card from '../landing/elements/Card'
import Link from 'next/link'
import Image from '../landing/elements/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/components/IvyStore.module.css'

const sofaTypes = [
  { title: 'Sectional', image: '/img/ivystore/sectional.png' },
  { title: 'Sofa', image: '/img/ivystore/sofa.png' },
  { title: 'Loveseat', image: '/img/ivystore/loveseat.png' },
]

export default function SectionSofaTypes ({ stateSlug, citySlug }) {
  return (
    <Block>
      <Col xs={12}>
        <h2 className={'fs-4 m-0'}>
          Explore Our Sofa Categories
        </h2>
      </Col>
      {sofaTypes.map(type => (
        <Col
          key={type.title}
          xs={12} md={6} lg={4}
        >
          <Card className={`position-relative ${styles.imageCard}`}>
            <div className={styles.imageGallery}>
              <Image
                title={`Find a used and inexpensive ${type.title} in one click`}
                src={type.image}
              />
            </div>

            <div className={'d-flex flex-column gap-3'}>
              <h3 className={'m-0 fs-5'}>
                {type.title}
              </h3>
              <div>
                <Link
                  href={`/products/?sofaType=${type.title}${stateSlug ? `&state=${stateSlug}` : ''}${citySlug ? `&city=${citySlug}` : ''}`}
                  className={`text-black ${styles.productLink}`}
                >
                  View {type.title}s
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    width={16}
                    height={16}
                    className={'ms-2'}
                  />
                </Link>
              </div>
            </div>
          </Card>
        </Col>
      ))}
    </Block>
  )
}