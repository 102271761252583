import { useEffect } from 'react'

export default function LandingWrapper ({
  children,
  className,
  gap = '6rem',
}) {
  useEffect(() => {
    const handler = () => {
      const observerOptions = {
        threshold: 0.1
      }

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-up')
            observer.unobserve(entry.target)
          }
        })
      }, observerOptions)

      document.querySelectorAll('.landing-block').forEach(block => {
        observer.observe(block)
      })
    }

    document.addEventListener('scroll', handler)
    handler()

    return () => {
      document.removeEventListener('scroll', handler)
    }
  }, [])

  return (
    <div
      className={`d-flex flex-column ${className}`}
      style={{
        gap,
        marginBottom: '4rem'
      }}
    >
      {children}
    </div>
  )
}
