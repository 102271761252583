import Row from 'react-bootstrap/Row'

export default function Block ({
  background = '',
  color = 'text-black',
  fullWidth = false,
  backgroundImage,
  alignVertical,
  leftGradient,
  noAppearanceAnimation = false,
  children,
  className,
  g = 'g-3 g-md-4',
  id,
}) {

  const containerClass = fullWidth
    ? 'container-fluid p-0'
    : 'container'

  const hasMultipleChildren = Array.isArray(children)

  return (
    <div
      id={id}
      className={`position-relative ${containerClass} ${noAppearanceAnimation ? '' : 'landing-block'} ${className}`}
    >
      <div
        className={`${background} ${color} ${fullWidth ? 'p-0' : 'br-3'}`}
        style={backgroundImage ? {
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        } : {}}
      >
        {leftGradient && (
          <div
            className={`position-absolute top-0 bottom-0 left-0 right-0 ${fullWidth ? '' : 'br-3'}`}
            style={{
              height: '100%',
              width: '100%',
              background: 'linear-gradient(to right, rgba(10,5,0, 1) 10%, rgba(0,0,0, 0))',
            }}
          />
        )}

        <div className={`position-relative ${fullWidth ? 'container' : ''}`}>
          <Row className={`${hasMultipleChildren ? g : ''} ${alignVertical ? 'align-items-center' : ''}`}>
            {children}
          </Row>
        </div>
      </div>
    </div>
  )
}
